import _ from 'lodash';
import React, { useState } from 'react';
import { FaCloudDownloadAlt, FaPlus, FaMinus } from 'react-icons/fa';
import { Collapse } from '@blueprintjs/core';

import PageHeader from '../PageHeader';
import BookTeaser from '../BookTeaser';
import OfficeInfo from '../Contact/OfficeInfo';
import data from '../../data/downloads';
import styles from './downloads.module.scss';

const Downloads = () => {
  const [isImperfectOpen, setIsImperfectOpen] = useState(true);
  const [isMasterpieceOpen, setIsMasterpieceOpen] = useState(false);
  const [isAnxietyOpen, setIsAnxietyOpen] = useState(false);

  const downloadIcon = <FaCloudDownloadAlt size={84} color="#4e839b" />;
  const plusIcon = <FaPlus size={20} color="#45ab7f" />;
  const minusIcon = <FaMinus size={20} color="#4e839b" />;

  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108930/headerBackgroundContact_lxkmce.jpg';

  const imperfectlyGoodDownloads = _.map(data.imperfectlyGood, ({ title, link, image }, index) => {
    return (
      <li key={`${index}_${title}`}>
        <div>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </div>
        {image && (
          <div className={styles.downloadLinkImageContainer}>
            <a target="_blank" href={link} rel="noopener noreferrer">
              <img
                className={styles.image}
                src={image}
                alt="A Faith Leader's Guide to Scrupulosity OCD"
              />
            </a>
          </div>
        )}
      </li>
    );
  });

  const letGoOfAnxietyDownloads = _.map(data.letGoOfAnxiety, ({ title, link }, index) => {
    return (
      <li key={`${index}_${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const handleClick = book => {
    if (book === 'Imperfectly Good') {
      setIsImperfectOpen(!isImperfectOpen);
    } else if (book === 'The Masterpiece Mindset') {
      setIsMasterpieceOpen(!isMasterpieceOpen);
    } else if (book === 'Let Go of Anxiety') {
      setIsAnxietyOpen(!isAnxietyOpen);
    }
  };

  return (
    <main>
      <PageHeader pageCategory="Books" pageName="Free Downloads" headerImage={background} />
      <div className={styles.wrapper}>
        <div className={styles.messageContainer}>
          {downloadIcon}
          <p className={styles.title}>
            Click a book title below to view and access your Free&nbsp;Downloads
          </p>
        </div>

        <div className={styles.collapseContainer}>
          <div className={styles.collapse}>
            <div className={styles.collapseHeader} onClick={() => handleClick('Imperfectly Good')}>
              <span className={styles.icon}>{isImperfectOpen ? minusIcon : plusIcon}</span>
              Imperfectly Good
            </div>
            <Collapse keepChildrenMounted isOpen={isImperfectOpen}>
              <div className={styles.downloadsContainer}>
                <ul>{imperfectlyGoodDownloads}</ul>
              </div>
            </Collapse>
          </div>
          <div className={styles.collapse}>
            <div className={styles.collapseHeader} onClick={() => handleClick('Let Go of Anxiety')}>
              <span className={styles.icon}>{isAnxietyOpen ? minusIcon : plusIcon}</span>
              Let Go of Anxiety
            </div>
            <Collapse keepChildrenMounted isOpen={isAnxietyOpen}>
              <div className={styles.downloadsContainer}>
                <ul>{letGoOfAnxietyDownloads}</ul>
              </div>
            </Collapse>
          </div>
        </div>

        <div className={styles.booksWrapper}>
          <div className={styles.bookCard}>
            <BookTeaser bookName="Imperfectly Good" />
          </div>
          <div className={styles.bookCard}>
            <BookTeaser bookName="Let Go of Anxiety" />
          </div>
        </div>
      </div>
      <OfficeInfo />
    </main>
  );
};

export default Downloads;
