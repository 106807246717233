const downloads = {
  imperfectlyGood: [
    {
      title: "A Faith Leader's Guide to Scrupulosity OCD",
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1688919931/a-faith-leaders-guide-to-scrupulosity.pdf',
      image:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1688920957/faith-leaders-guide-scrupulosity-ocd-cover.png',
    },
    {
      title: 'Live with Faith: A Guide for LDS Teens',
      link: 'https://res.cloudinary.com/dewd4pral/image/upload/v1721094239/live-with-faith.pdf',
    },
    {
      title: 'Mindful Conversations',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Mindful_Conversations.pdf',
    },
    {
      title: 'Refraining from Compulsions',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Refraining_from_compulsions_by_writing.pdf',
    },
    {
      title: 'Walking Through the Library Halls',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Walking_Through_the_Library_Halls.pdf',
    },
    {
      title: 'Interoceptive Exposures Activity Chart',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Interoceptive_Exposures_Activity_Chart.pdf',
    },
    {
      title: 'Values-Based Script Preparation Chart',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Values-Based_Script_Preparation_Chart.pdf',
    },
    {
      title: 'Imaginal Exposure Chart',
      link:
        'https://res.cloudinary.com/dewd4pral/image/upload/v1680980373/Imaginal_Exposure_Chart.pdf',
    },
    {
      title: 'Preparation Chart',
      link: 'https://res.cloudinary.com/dewd4pral/image/upload/v1680980374/Preparation_Chart.pdf',
    },
    {
      title: 'Activity Chart',
      link: 'https://res.cloudinary.com/dewd4pral/image/upload/v1680980374/Activity_Chart.pdf',
    },
    {
      title: 'Mix-It-Up Chart',
      link: 'https://res.cloudinary.com/dewd4pral/image/upload/v1680980374/Mix-It-Up_Chart.pdf',
    },
  ],
  letGoOfAnxiety: [
    {
      title: 'A Daily Experiment that will Change Your Panic Attacks',
      link:
        'https://res.cloudinary.com/dewd4pral/raw/upload/v1609800565/A_Daily_experiment_that_will_Change_Your_Panic_Attacks.pdf',
    },
    {
      title: 'Five Essential Steps to Making Social Media Work for You',
      link:
        'https://res.cloudinary.com/dewd4pral/raw/upload/v1609800658/Five_Essential_Steps_to_Making_Social_Media_Work_for_You.pdf',
    },
    {
      title: 'Thirty-One Simple Mindfulness Practices',
      link:
        'https://res.cloudinary.com/dewd4pral/raw/upload/v1609800711/31_Simple_Mindfulness_Exercises.pdf',
    },
  ],
};

export default downloads;
